/* Custom styles for the PhoneInputInput class */
.scoped-phone-input .PhoneInputInput {
    padding: 1rem;
    font-size: 18px;
    font-weight: 500;
    background-color: #ffffff;
        color: #000000;
        border: 1px solid #ccc;
        pointer-events: auto;
        /* Ensure it's interactive when not disabled */
}


/* When disabled, gray background and non-editable */

.scoped-phone-input.input-disabled .PhoneInputInput {
    background-color: #f0f0f0;
    /* Gray background */
    color: #7a7a7a;
    /* Muted text color */
    pointer-events: none;
    /* Disable interactions */
    cursor: not-allowed;
}

/* Ensure the flag and other parts of PhoneInput remain functional */

.scoped-phone-input.input-disabled .PhoneInput {
    pointer-events: auto;
    /* Allow interaction with the flag dropdown */
}